import React from "react";
import "../models/Home.css";

const Home = () => {
  const email = "info@costen.co.ke"; // Replace with your email
  const phoneNumber = "+254725313177"; // Replace with your WhatsApp number

  return (
    <div className="home-container">
      <h1 className="home-title">Welcome to Costen Software Solutions</h1>
      <p className="home-description">
        We are happy to have you as our primary client. We offer the following services:
      </p>
      <ol className="services-list">
        <li>Customized software development</li>
        <li>Website creation, design, maintenance, and upgrades</li>
        <li>Software installation, upgrades, and sales</li>
        <li>Computer servicing</li>
        <li>Networking</li>
        <li>Graphic design</li>
      </ol>

      {/* Contact Section */}
      <div className="contact-section">
      <h1 className="home-title">Reach us on</h1>
      <a href={`mailto:${email}`} className="contact-button email-button">
          Email Us
        </a>
        <a 
          href={`https://wa.me/${phoneNumber.replace(/\D/g, '')}`} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="contact-button whatsapp-button"
        >
          Chat on WhatsApp
        </a>
      </div>
    </div>
  );
};

export default Home;
