import React from "react";
import "../models/Topnavbar.css";

const Navbar = () => {
  return (
    <div className="navbar-banner">
      <div className="navbar-header">
        <img src="/logocosten.png" alt="Logo" className="navbar-logo" />
        <h1 className="navbar-title">Costen Software Solutions</h1>
      </div>
      <ul className="navbar-menu">
        <li><a href="/" className="navbar-link">Home</a></li>
        <li><a href="/aboutus" className="navbar-link">About</a></li>
        <li><a href="/contactus" className="navbar-link">Contact</a></li>
      </ul>
    </div>
  );
};

export default Navbar;
