import React from "react";
import "../models/Home.css";

const About = () => {
  return (
    <div className="home-container" >
      <h1 className="home-title">Background</h1>
      <p className="home-description">
        Costen Software Solutions was founded by a group of computer scientists who have, on many occasions, 
        experienced institutional gaps that require Information Technology solutions. With Vision 2030 objectives 
        and Millennium Development Goals in mind, they decided to come together and legalize their activities. 
        We develop projects, applications, and software that meet clients' objectives and terms of reference (T.O.R). 
        Our approach involves thorough analysis and design to ensure we create the best solutions using current technologies.
      </p>
      <p className="home-description">
        As part of enhancing ICT in Kenya and globally, we assist new developers in solving their technical challenges.
        Our solutions incorporate futuristic thinking and innovation, with quality as our top priority.
      </p>

      <h1 className="home-title">Our Clients</h1>
      <p className="home-description">
        Our clients include organizations, churches, companies, agencies, SACCOs, colleges, secondary and primary schools, 
        students working on academic projects, and aspiring technologists looking to learn ICT platforms.
      </p>
      <p className="home-description">
        We guide college students in their projects and assist companies and institutions in upgrading or developing new systems
        while solving their ICT challenges. <strong>Feel free to consult us!</strong>
      </p>

      <h1 className="home-title">Our Vision</h1>
      <p className="home-description">
        To be a leader in the development of robust and cutting-edge software solutions.
      </p>

      <h1 className="home-title">Our Mission</h1>
      <p className="home-description">
        To provide business-oriented and end-user-friendly software solutions that meet the core values, mission, and vision of our clients 
        in the global competitive market using web, standalone, and mobile technologies.
      </p>

      <h1 className="home-title">Core Values</h1>
      <p className="home-description">We are guided by the following values:</p>
      <ol className="services-list">
        <li>Integrity</li>
        <li>Professionalism</li>
        <li>Collaborative development</li>
        <li>Timely delivery</li>
      </ol>
    </div>
  );
};

export default About;
