import React from "react";
import "../models/Contact.css";

const Contactus = () => {
  return (
    <div className="signup-container">
      <div className="signup-column left-column">
        <h2>Welcome</h2>
        <p>We are happy hearing from you. Feel free to drop as a message anytime!</p>
        <p>For Urgent Support; you can call us on +254725313177 | Email :info@costen.co.ke</p>
      </div>
      <div className="signup-column right-column">
        <form className="signup-form">
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" required />

          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" required />

          <label htmlFor="message">Message</label>
          <textarea className="message-textarea" id="message" name="message" required />

          <button type="submit">Contact Us</button>
        </form>
      </div>
    </div>
  );
};

export default Contactus;
